import React from 'react';
import PageLayout from '@containers/PageLayout';
import Section from '@containers/Section';

const AboutPage = () => (
  <PageLayout title="About" description="Steven Sajja about page">
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-screen-xl mx-auto py-16 px-4 lg:pl-0 lg:px-8 sm:px-6">
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Meet Sajja
          </h1>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              {/* <figure> */}
              <div className="relative pb-7/12 lg:pb-0">
                <img
                  src="https://res.cloudinary.com/steven-sajja/image/upload/v1600458004/Sajja%20Images/Steven_Sajja_Mugerwa_rhzeu0.png"
                  alt="Steven Sajja"
                  width={1184}
                  height={1376}
                  className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto"
                />
              </div>
              {/* <figcaption className="flex mt-3 text-sm text-gray-500">
                    <svg
                      className="flex-none w-5 h-5 mr-2 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Photograph by Marcus O’Leary
                  </figcaption> */}
              {/* </figure> */}
            </div>
          </div>
          <div>
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg leading-7 text-gray-500 mb-5">
                I am the Co-Founder and technology partner for{' '}
                <a
                  href="https://boragrowth.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bora Growth Partners
                </a>
                , a consulting company with a focus on digital transformation,
                innovation for small to large businesses in Sub-saharan Africa.
              </p>
            </div>
            <div className="prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p className="text-lg leading-7 text-gray-500 my-5">
                I focus on technology innovation & strategy, product development
                and new venture creation like{' '}
                <a href="https://picortex.com" target="_blank" rel="noreferrer">
                  PiCortex
                </a>
                , an ERP business management solution for small to large
                businesses.
              </p>
              <p className="text-lg leading-7 text-gray-500 mb-5">
                I have spent the majority of the last decade in a number of tech
                roles from user experience researcher to mobile engineer,
                software engineer and product manager. I have learnt the ins and
                outs of the financial tech space — from payments, lending &
                value added services .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
);

export default AboutPage;
